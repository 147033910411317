import React, { useState, useEffect } from "react";
import './Ordercard.css'; // Ensure the CSS file is properly updated
import axios from "axios";
import { useTranslation } from './TranslationContext';
import "./Product_detail.css";
import "./Showtimes.css";
import { useNavigate } from 'react-router-dom';
import Web3 from "web3";



const Ordercard = () => {
  const [addons, setAddons] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]); // Store selected addons
  const [cards1, setCards1] = useState({ totalamt: 0, ordertotal: 0, offer: 0 });
  const [movieDetails, setMovieDetails] = useState(null);
  const { translate } = useTranslation();
  const [transactionId, setTransactionId] = useState(''); // To store transaction ID
  const [coupons, setCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0); // Add grandTotal as state


  const seat_ids = localStorage.getItem("seat_ids");
  const seat_count = localStorage.getItem("seat_count");
  const total_amount = Number(localStorage.getItem("tot_amt")); // Ensure total_amount is a number
  const screen_name = localStorage.getItem("screen_name");
  const user_id = localStorage.getItem("admin_id");
  const screen_id = localStorage.getItem('screen_id');//localStorage.getItem('screen_id');
  const date = localStorage.getItem('date'); // Example date
  const movie_id = localStorage.getItem('movie_id');// Example movie ID
  const timeslot_id =localStorage.getItem('timeslot_id'); // Example timeslot ID
  const api_url = localStorage.getItem('api_url');
  const convenience_fees = 59; // Convenience fee constant
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAddons = async () => {
      try {
        const api_url = localStorage.getItem('api_url');
        const response = await axios.get(api_url + '/getAddons');
        setAddons(response.data.addon);

        const movie_id = localStorage.getItem('movie_id');// Example movie ID
        const movieResponse = await axios.get(`${api_url}/getmovie_details/${movie_id}`);
        setMovieDetails(movieResponse.data.movie_details);
        setCoupons(movieResponse.data.coupons);
      } catch (error) {
        console.error("Error fetching addons:", error);
      }
    };

    fetchAddons();
  }, []);

  useEffect(() => {
    const addonTotal = calculateAddonTotal();
    const updatedGrandTotal = Number(total_amount) + Number(convenience_fees) + Number(addonTotal);
    setGrandTotal(updatedGrandTotal);
  }, [total_amount, convenience_fees, selectedAddons]);

  // Handle addon add/remove
  const handleAddonClick = (addon) => {
    if (selectedAddons.some(selected => selected.id === addon.id)) {
      // If addon is already added, remove it
      setSelectedAddons(selectedAddons.filter(selected => selected.id !== addon.id));
    } else {
      // Add the selected addon
      setSelectedAddons([...selectedAddons, addon]);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const handleCouponSelection = (coupon) => {

    if (coupon.amount_upto >= grandTotal) {
      alert('This coupon can only be applied to orders above ₹' + coupon.amount_upto);
      return;
    }

    setSelectedCoupon(coupon);

    // Calculate discount amount
    const discount = Math.min(coupon.offer, coupon.amount_upto);
    setDiscountAmount(discount);

    // Update total amount
    const newTotalAmount = grandTotal - discount;
    setGrandTotal(newTotalAmount);
  };

  // Calculate total for the payment summary
  const calculateAddonTotal = () => {
    return selectedAddons.reduce((total, addon) => total + Number(addon.price), 0); // Ensure addon price is a number
  };

  // const addonTotal = calculateAddonTotal();
  // const updatedGrandTotal  = Number(total_amount) + Number(convenience_fees) + Number(addonTotal); // Ensure all values are numbers
  // setGrandTotal(updatedGrandTotal); // Update grand total


  const handleBookNow = async () => {

    try {
      // Step 1: Call the paymentorder API to create an order and transaction
      const paymentResponse = await axios.post(`${api_url}/paymentorder`, {
        tot_amt: grandTotal,
        transactionid: transactionId
      });
      const { transactionid, orderId } = paymentResponse.data;
      setTransactionId(transactionid);

      // Step 2: Initiate Razorpay payment
      const options = {
        key: 'rzp_test_JFlMQ0tOgzb3j8', // Example Razorpay test key
        amount: grandTotal * 100, // amount in paise
        currency: 'INR',
        name: 'Movie Ticket',
        description: 'Movie Ticket Transactions',
        image: 'https://example.com/your_logo',
        order_id: orderId,
        handler: async (response) => {
          const { razorpay_payment_id } = response;

          // Step 3: Call the savetransactions API after payment success
          const saveTransactionResponse = await axios.post(`${api_url}/savetransactions`, {
            user_id,
            payment_success_id: razorpay_payment_id,
            status: 'booked',
            transactionid: razorpay_payment_id,
            total_amt: grandTotal
          });

          if (saveTransactionResponse.data) {
            // Step 4: Call the bookings_confirm API after transaction is saved
            // const seat_ids = selectedSeats.map(seat => seat.seatId).join(',');

            const confirmBookingResponse = await axios.post(`${api_url}/bookings_confirm`, {
              screen_id,
              timeslot_id,
              movie_id,
              seat_ids,
              total_amount: grandTotal,
              transaction_id: razorpay_payment_id,
              date,
              user_id
            });

            if (confirmBookingResponse.data) {


            //   if (typeof window.ethereum !== "undefined") {
            //     const web3 = new Web3(window.ethereum);
            //     await window.ethereum.request({ method: "eth_requestAccounts" });
            //     const accounts = await web3.eth.getAccounts();
            //     console.log("Connected account:", accounts[0]);

            //     try {
            //       await axios.post(`${api_url}/buy_ticket`, {
            //         userAddress: accounts[0],
            //         ticketPrice:grandTotal,
            //       });
  
  
                  
            //     } catch (error) {
            //       alert("Transaction Failed: " + error.message);
            //     }
            // } else {
            //     console.error("MetaMask is not installed. Please install MetaMask.");
            //     alert("MetaMask is not installed. Please install MetaMask.");
            // }
            alert('Booking confirmed successfully!');
  
            navigate("/Myorders", { replace: true });

            } else {
              alert('Failed to confirm booking.');
            }
          } else {
            alert('Failed to save transaction.');
          }
        },
        prefill: {
          name: "Harsanth", // Example prefill data
          email: "harsanthjack@gmail.com",
          contact: "8667040195"
        },
        theme: {
          color: '#3399cc'
        }
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error('Error during booking process:', error);
      alert('An error occurred while processing your booking.');
    }
  };

  return (
    <div>
      <div className="movie-detail-page">
  {/* Movie Banner */}
  {movieDetails ? (
    <div className="movie-banner" style={{ backgroundImage: `url(${movieDetails.movie_image})` }}>
      <div className="movie-details-container">
        <div className="movie-image">
          <img src={movieDetails.movie_image} alt={movieDetails.movie_name} />
        </div>
        <div className="movie-info">
          <h2>{movieDetails.movie_name}</h2>
          <span>{translate('Rating')}: {movieDetails.ratings} / 10</span>
          <div className="stars">{'⭐'.repeat(Math.round(movieDetails.ratings))}</div>
          <p>{movieDetails.movie_type}</p>
          <p>{movieDetails.category}</p>
          <p>{movieDetails.language}</p>
          <p>{movieDetails.timing} | {new Date(movieDetails.release_date).toDateString()}</p>
        </div>
      </div>
    </div>
  ) : (
    <p>Loading movie details...</p>  
  )}
</div>

    <br></br>
   
    <div className="order-page">
      <div className="addon-container">
        <h2 className="addon-title">Grab a bite!</h2>
        <h5>Prebook Your Meal and Save More</h5>
        <div className="addon-category-buttons">
          <button>All</button>
        </div>
        <div className="addon-list">
          {addons.map((addon) => (
            <div key={addon.id} className="addon-card">
              <img src={require('./img/istock.jpg')} alt={addon.addon_name} className="addon-image" />
              <div className="addon-info" style={{textAlign:"left"}}>
                <h3>{addon.addon_name}</h3>
                <p>{addon.weight} g</p>
                <p>₹{addon.price}</p>
                <button
                  className={`addon-add-button ${selectedAddons.some(selected => selected.id === addon.id) ? 'added' : ''}`}
                  onClick={() => handleAddonClick(addon)}
                >
                  {selectedAddons.some(selected => selected.id === addon.id) ? 'Added' : 'Add'}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="payment-summary">
        <div>
        <h3>Booking Summary</h3>

        <p className='coupon_a'>{selectedCoupon ? `Coupon applied: ${selectedCoupon.coupon_code}` : 'No coupon applied'}</p>
         
        <div style={{ display: "flex" }}>
          <p style={{ textAlign: "left" }}><b>{screen_name} - {"(" + seat_count + " Tickets)"}</b></p>
          <p style={{ marginLeft: "45%" }}>₹{total_amount}</p>
        </div>
        <div style={{ display: "flex" }}>
          <p style={{ textAlign: "left" }}><b>Convenience fees</b></p>
          <p style={{ marginLeft: "46%" }}>₹{convenience_fees}</p>
        </div>
        <hr />
        <div style={{ display: "flex" }}>
          <p style={{ textAlign: "left" }}><b>Sub total</b></p>
          <p style={{ marginLeft: "62%" }}>₹{Number(total_amount) + Number(convenience_fees)}</p>
        </div>
        {/* Display selected addons in the payment summary */}
        {selectedAddons.length > 0 && (
          <>
            <p style={{ textAlign: "left" }}><b>Selected Addons</b></p>
            {selectedAddons.map(addon => (
              <div key={addon.id} style={{ display: "flex" }}>
                <p style={{ textAlign: "left" }}><b>{addon.addon_name}</b></p>
                <p style={{ marginLeft: "72%" }}>{"₹" + Number(addon.price)}</p>
              </div>
            ))}
          </>
        )}

        <hr />
        <div style={{ display: "flex" }}>
          <p style={{ textAlign: "left" }}><b>Amount Payable</b></p>
          <p style={{ marginLeft: "48%" }}><b>₹{grandTotal}</b></p>
        </div>

        <button className="btn btn-primary" onClick={handleBookNow}>Pay Now</button>
      </div>
      <br></br>
      
      <div className='coupon_selection'>
      <p style={{textAlign:"left"}}><b>{translate('available coupons')}</b></p>
      {coupons && coupons.length > 0 ? (
        coupons.map(coupon => (
          <div key={coupon._id}>
            <input
              type="radio"
              id={coupon.coupon_code}
              name="coupon"
              value={coupon.coupon_code}
              checked={selectedCoupon && selectedCoupon.coupon_code === coupon.coupon_code}
              onChange={() => handleCouponSelection(coupon)}
            />
            <label htmlFor={coupon.coupon_code}>
              {coupon.coupon_code} - {coupon.offer}% off up to ₹{coupon.amount_upto}
            </label>
            <p>{translate('validupto')}: {formatDate(coupon.valid_date)}</p>
          </div>
        ))
      ) : (
        <p>{translate('no available coupons')}</p> // Message if no coupons are available
      )}
    </div>
</div>

    </div>
    </div>
  );
};

export default Ordercard;
