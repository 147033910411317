import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import "./Prductslide.css";
import Product_rating from './Product_rating';

const Prductslide = () => {
  const [cards, setCards] = useState([]);
  const navigate = useNavigate();

  const handleDivClick = (id) => {
    localStorage.setItem("movie_id", id);
    navigate("/Movies", { replace: true }); 
  };

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const api_url = localStorage.getItem('api_url');
        const city = localStorage.getItem('city');
        const user_id = localStorage.getItem("admin_id");

        // const response = await axios.get(api_url + '/getmovies/'+city);

        const response = await axios.post(`${api_url}/recommendations`, {
          userId: user_id
        });
        setCards(response.data.recommendations);
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };

    fetchCards();
  }, []);

  const [startIndex, setStartIndex] = useState(0);

  const onNextClick = () => {
    const newIndex = startIndex + 5;
    if (newIndex < cards.length) {
      setStartIndex(newIndex);
    }
  };

  const onPrevClick = () => {
    const newIndex = startIndex - 5;
    if (newIndex >= 0) {
      setStartIndex(newIndex);
    }
  };

  // Calculate the container width
  const visibleCards = cards.slice(startIndex, startIndex + 5);
  const containerWidth = visibleCards.length === 5 ? '100%' : '75%';

  return (
    <div className="slideshows">
      {cards.length === 0 ? (
        <p className="no-recommendations">No recommendations available.</p>
      ) : (
        <>
          <div className="card-container" style={{ width: containerWidth }}>
            {visibleCards.map((card) => (
              <div key={card.id} className="card">
                {card.movie_image ? (
                  <img
                    className='med_img'
                    src={card.movie_image}
                    alt={card.movie_name}
                    onClick={() => handleDivClick(card.id)}
                  />
                ) : (
                  <div className="placeholder-image" onClick={() => handleDivClick(card.id)}>
                    No Image Available
                  </div>
                )}
                <div className="medicine-details">
                  <p style={{ textAlign: "left" }}><b>{card.movie_name}</b></p>
                  <p style={{ textAlign: "left", marginTop: "-10px" }}>{card.genre}</p>
                </div>
              </div>
            ))}
          </div>
          <br />
          {visibleCards.length > 0 && (
            <div className="controls">
              <button
                className="bu_n"
                onClick={onPrevClick}
                disabled={startIndex === 0}
              >
                &lt;
              </button>
              <button
                className="bu_n"
                onClick={onNextClick}
                disabled={startIndex + visibleCards.length >= cards.length}
              >
                &gt;
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
  
};

export default Prductslide;
