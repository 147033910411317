import React from 'react';
import './RightSideMenu.css';
import { useNavigate } from 'react-router-dom';

const RightSideMenu = ({ isOpen, onClose }) => {

    const navigate = useNavigate();

    const handleprofile1 = () => {
        navigate('/Myorders');
    };

     const handlelogout = () => {
        localStorage.clear();
        navigate('/Login');
    };
    const handlenotifi = () => {
        navigate('/Mynotifications');
    };
    const handlehelp = () => {
        navigate('/Help');
    };
    const handleprofile = () => {
        navigate('/Profile');
    };

    return (
        <>
            <div className={`right-side-menu ${isOpen ? 'open' : ''}`}>
                <div className="menu-header">
                    <div>
                        <h3>Hello</h3>
                        <p>Edit Profile</p>
                    </div>
                    <button className="close-button" style={{display:"none"}} onClick={onClose}>&times;</button>
                </div>
                <div className="menu-content">
                    <ul>
                        <li onClick={handlenotifi}>
                            {/* <img src={require('./img/noti-icon.png')} alt="Profile" /> */}
                            <span className="menu-item-text">Notifications</span>
                            <span className="arrow"> {">"} </span>
                        </li>
                        <hr />
                        <li onClick={handleprofile1}>
                            {/* <img src={require('./img/orders-icon.png')} alt="Settings" /> */}
                            <span className="menu-item-text">Your Orders</span>
                            <span className="arrow">{">"} </span>
                        </li>
                        <hr />
                        <li onClick={handlehelp}>
                            {/* <img src={require('./img/help-icon.png')} alt="Help" /> */}
                            <span className="menu-item-text">Help & Support</span>
                            <span className="arrow">{">"}</span>
                        </li>
                        <hr />
                        <li onClick={handleprofile}>
                            {/* <img src={require('./img/help-icon.png')} alt="Help" /> */}
                            <span className="menu-item-text">Account & Settings</span>
                            <span className="arrow">{">"}</span>
                        </li>
                        <hr />
                        <li onClick={handlelogout}>
                            {/* <img src={require('./img/logout-icon.png')} alt="Logout" /> */}
                            <span className="menu-item-text">Logout</span>
                            <span className="arrow"> {">"} </span>
                        </li>
                    </ul>
                </div>
            </div>
            {isOpen && <div className="overlay" onClick={onClose}></div>}
        </>
    );
};

export default RightSideMenu;
