import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Catslide.css"; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';

const Catslide = () => {
  const [cards, setCards] = useState([]);
  const [categories, setCategories] = useState([]); // Store categories from API
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [selectedGenre, setSelectedGenre] = useState(""); // State for genre filter
  const [startIndex, setStartIndex] = useState(0); // For pagination

  const navigate = useNavigate();

  const handleDivClick = (id) => {
    localStorage.setItem("movie_id", id);
    navigate("/Movies", { replace: true });
  };
  const handleDivClick1 = () => {
    navigate("/Moviesupcome", { replace: true });
  }; 

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const api_url = localStorage.getItem('api_url');
        const city = localStorage.getItem('city');
        const response = await axios.get(api_url + '/getmovies/'+city);
        setCards(response.data.moviedetails);

        const response1 = await axios.get(api_url + '/getCategories');
        setCategories(response1.data.categories); // Store categories for filtering
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCards();
  }, []);

  // Filter movies based on search query and selected genre (category)
  const filteredCards = cards.filter((card) =>
    card.movie_name.toLowerCase().includes(searchQuery.toLowerCase()) &&
    (selectedGenre === "" || card.genre === selectedGenre)
  );



  const visibleCards = filteredCards.slice(startIndex, startIndex + 5);

  return (
    <div className="">
      {/* Search and Filter Section */}

      <h1>Upcoming Movies</h1>
      <div className="search-filter-container">
        <input
          type="text"
          placeholder="Search Movies"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-bar"
        />
        <select
          value={selectedGenre}
          onChange={(e) => setSelectedGenre(e.target.value)}
          className="filter-dropdown"
        >
          <option value="">All Genres</option>
          {/* Dynamically populate genres from the categories API */}
          {categories.map((category) => (
            <option key={category.cat_id} value={category.cat_name}>
              {category.cat_name}
            </option>
          ))}
        </select>

           <div style={{width:"30%"}} >
            <p onClick={() => handleDivClick1()}  style={{cursor:"pointer;"}}>Explore Upcoming Movies</p>
           </div>

      </div>

      {/* Movie Cards Section */}
      <div className="car_d row">
        {visibleCards.map((card, index) => (
          <div
            key={card._id}
            className="col-md-2"
            style={{
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              borderRadius: "5px",
              height: "240px",
              margin: "10px",
              padding: "20px",
            }}
            onClick={() => handleDivClick(card._id)}
          >
            <img className="med_img" src={card.movie_image} alt={card.movie_name} />
            <hr />
            <div className="medicine-details">
              <p>{card.movie_name}</p>
            </div>
          </div>
        ))}
      </div>

    
    </div>
  );
};

export default Catslide;
